.variantsLoading {
  display: flex;
  flex-flow: row nowrap;
  text-align: left;
  align-items: center;
  margin: 0 0 2rem;
}

.threeDots {
  flex: 0 0 3rem;
  margin-right: 1rem;
}

.exchangeOptionDropdown {
  margin-bottom: 2rem;
}
