@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/typography';

.contactInfo {
  @include typography.medium(16px, 24px);
  color: #757784;
}

.contactInfoRow {
  margin: 0 0 0.25rem;
}

.contactPlaceholder {
  @extend .contactInfoRow;

  display: block;
}

.divider {
  border-bottom: 1px solid colors.$inputBorderColor;
}

.refundMethod {
  @include typography.regular(16px, 24px);
  color: colors.$greySubInfo;
}

.dropOffLocation {
  @include typography.regular(14px, 20px);

  border: 1px solid colors.$inputBorderColor;
  padding: 0.75rem;
  border-radius: 0.5rem;
  margin: 0.5rem 0 0;
  display: flex;
  flex-flow: row nowrap;
  color: colors.$greySubInfo;

  strong {
    color: #1b1c22;
  }
}

.dropOffIcon {
  flex: 0 0 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  background: colors.$logoColor;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.returnMethod {
  @include typography.regular(16px, 24px);
  color: colors.$greySubInfo;
}

.items {
  @include mediaQueries.forTabletPortraitUp {
    border-top: 1px solid colors.$inputBorderColor;
  }
}

.itemsHeader {
  margin: 1.5rem 0 1rem;
  display: flex;
  flex-flow: row nowrap;
}

.header {
  @include typography.medium(20px, 30px);
}

.subHeader {
  @include typography.medium(18px, 28px);
  margin: 0 0 0.5rem;
}

.changeLink {
  margin-left: auto;
  color: colors.$linkColor;
  @include typography.medium(16px, 16px);
  text-decoration: none;
  align-self: center;
}

.returnItemsGroup {
  margin: 0 0 1.5rem;
}

.productThumbnailContainer {
  border: 0.25px solid #6a7281;
  padding: 0.75rem 1rem;
  border-radius: 10px;
  margin: 0 0 0.75rem;
}

.productThumbnail {
  margin: 0 0 1rem;
}

.productName {
  @include typography.medium(20px, 30px);
  flex: 1 1 100%;
}

.variantName {
  @include typography.regular(14px, 21px);
  color: colors.$rbSecondary;
}

.oldVariantName {
  @extend .variantName;

  text-decoration: line-through;
}

.lineItem {
  @include typography.regular(16px, 24px);

  display: flex;
  flex-flow: row nowrap;
  color: colors.$greySubInfo;
  margin: 0 0 0.75rem;

  .amount {
    padding-left: 1.5rem;
    margin-left: auto;
    color: colors.$rbSecondary;
  }
  .bonusAmount {
    padding-left: 1.5rem;
    margin-left: auto;
    font-weight: bold;
    color: colors.$lightSuccessMain;
  }
}

.total {
  @extend .lineItem;

  font-weight: 600;
  color: colors.$rbSecondary;
}

.taxCommunications {
  @include typography.regular(12px, 24px);

  color: colors.$greySubInfo;
  margin: 0 0 0.75rem;
  padding-top: 0.75rem;
  line-height: 16.8px;
}

.itemContent {
  flex: 1;
}

.returnMethodText {
  flex: 1 1 120px;
  text-align: right;
  color: colors.$greySubInfo;
  font-style: italic;
}

.returnMethodContainer {
  display: flex;
}

.spacingBottom {
  margin-bottom: 10px;
}
