@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';

.container {
  &:not(:last-child) {
    margin-bottom: 0;
  }

  a {
    @include mixins.linkAppearance;
  }
}

.headerInfo {
  @include typography.regular(16px, 28px);

  color: colors.$rbSecondary;
  margin: -2.5rem 0 1.5rem;

  @include mediaQueries.forTabletPortraitUp {
    text-align: center;
  }

  @include mediaQueries.forPhoneOnly {
    margin-top: -0.5rem;
  }
}

.itemCount {
  @include typography.medium(18px, 28px);

  margin: 0 0 0.25rem;
  display: flex;
}

.productSelectionWarning {
  @include typography.regular(14px, 21px);
  color: colors.$greySubInfo;
  padding-bottom: 0.5rem;
}

.selectedItemCount {
  @include typography.light(16px, 28px);

  margin-left: auto;
}

.lineItems {
  margin: 0;
}

.blob {
  margin-bottom: 1.5rem;

  .itemCount {
    margin: 0 0 1rem;
  }
}

.returnPolicy {
  @include mixins.linkAppearance;

  font-weight: 500;
  margin-left: 1rem;
}

.alertWrapper {
  margin-bottom: 30px;

  &.selectedItems {
    margin: 8px 0px;
  }
}

.specialHandling {
  margin-bottom: 16px;
}
