@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';
@use 'src/sass/variables';

$smallViewportBreakpoint: 768px;

.mobileStepper {
  padding: 0.4rem;
  user-select: none;
  display: flex;
  justify-content: space-between;

  @media (min-width: (variables.$stepperMobileBreakpoint + 1)) {
    display: none;
  }

  .currentStep {
    @include typography.medium(16px, 20px);
    margin: 0 0 0.25rem;
  }

  .progress {
    @include typography.regular(14px, 20px);
    color: colors.$greySubInfo;
  }

  .stepperLeftContent {
    flex: 1;
    display: flex;
    align-items: flex-start;
  }

  .stepperRightContent {
    flex: 1;
  }

  .stepDetails {
    display: grid;
    justify-items: center;
  }

}

.fullWidthStepper {
  height: variables.$floatingHeaderHeight;

  @media (max-width: variables.$stepperMobileBreakpoint) {
    display: none;
  }
}

.flexboxStepperWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  user-select: none;
  height: inherit;
  position: inherit;
  margin: 0 auto;
}

.chipAdminStepperWrapper {
  position: fixed;
  right: 24px;
}

.fullWidthStepperWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  user-select: none;
  position: relative;
  height: inherit;
  max-width: variables.$siteMaxWidth;
  margin: 0 auto;
}

.storeBranding {
  padding: 0.5rem 1.5rem 0;
  position: absolute;
  top: 0;
  right: 100%;
}

.logo {
  max-height: 32px;
}

.steps {
  @include typography.medium(14px, 20px);

  flex: 1 1 variables.$siteMaxWidth;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 100%;
}

.step {
  counter-increment: nextStepCounter;

  &:not(:first-child) {
    margin-left: 2rem;
  }
}

.backArrowContainer {
  display: none;

  @media (max-width: variables.$stepperMobileBreakpoint) {
    display: flex;
    margin: 0.25rem 0;
  }
}

.backArrowIcon {
  @include typography.regular(14px, 20px);
  @include mixins.linkAppearance;

  display: flex;
  background: none;
  border: none;
  align-items: center;
  padding: 10px;
}

.container {
  padding: 0 1rem;

  p {
    @include typography.regular(16px, 26px);

    margin: 0 0 1rem;
  }

  small {
    @include typography.regular(14px, 21px);

    display: block;
    margin: 2.5rem 0 1.5rem;
    color: #676565;
  }

  a {
    @include mixins.linkAppearance;
  }
}

.modal {
  max-width: 770px;
}

.customFooterStyles {
  display: flex;
  justify-content: flex-end;
}
