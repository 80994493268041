@use 'src/sass/colors';

.container {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  user-select: none;
}

.content {
  flex: 1;
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
}

.loadingText {
  // Store font is not available for this layout, use sans-serif instead
  font: 500 18px/28px sans-serif;
  color: colors.$rbSecondary;
  margin: 0.75rem 0;
}

.dotPulse {
  color: colors.$logoColor;
}

.poweredBy {
  color: colors.$logoColor;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 2rem 0 4rem;
  justify-content: center;
}

.logo {
  margin-left: 0.75rem;
}
