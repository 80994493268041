@use 'src/sass/colors';
@use 'src/sass/mixins';
@use 'src/sass/typography';

.container {
  @include mixins.boxAppearance;

  padding: 4rem 1.5rem;
  color: colors.$rbSecondary;
  text-align: center;

  h2,
  p {
    max-width: 542px;
    margin: 0 auto;
  }

  h2 {
    @include typography.medium(24px, 36px);

    margin-bottom: 1rem;
  }

  p {
    @include typography.regular(16px, 24px);

    margin-bottom: 2.5rem;
  }
}

.confirmationImage {
  display: block;
  max-width: 118px;
  margin: 0 auto 2.5rem;
  margin-top: 2rem;
}

.actions {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  align-items: center;
}

.backToShopping {
  @include mixins.linkReset;
  @include mixins.buttonAppearance;

  min-width: 327px;
  margin: 0 0 2.5rem;
}

.createAnotherReturn {
  @include mixins.linkAppearance;
  @include typography.bold(16px, 24px);
}
