@use 'src/sass/colors';
@use 'src/sass/mixins';

.chipRoot {
  width: auto;
  text-align: center;
  max-width: 100%;
  font-size: 0.8125rem;
  position: relative;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 16px;
  white-space: nowrap;
  outline: 0;
  text-decoration: none;
  border:0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.87);
}

.chipSizeSmall {
  height: 24px;
}

.chipColorPrimary {
  background-color: #1976d2;
  color: #fff;
}

.chipColorSecondary {
  background-color: rgb(156, 39, 176);
  color: #fff;
}

.chipColorError {
  background-color: rgb(211, 47, 47);
  color: #fff;
}

.chipColorInfo {
  background-color: rgb(2, 136, 209);
  color: #fff;
}

.chipColorSuccess {
  background-color: #2e7d32;
  color: #fff;
}

.chipColorWarning {
  background-color: rgb(237, 108, 2);
  color: #fff;
}

.chipLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
}
