@use 'src/sass/colors';
@use 'src/sass/typography';

.container {
  @include typography.regular(16px, 28px);

  color: colors.$errorColor;

  &:not(:only-child) {
    margin: 0 0 1.5rem;
  }
}
