@use 'src/sass/colors';

@import-normalize; /* Use normalize.css (https://create-react-app.dev/docs/adding-css-reset) */
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');

/**
 * Global styles for the entire application go in this file
 */

:root {
  accent-color: colors.$brandPrimaryColor;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: colors.$rbSecondary;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: colors.$linkColor;
  text-decoration: none;
  cursor: pointer;

  &:focus {
    // Change the outline-color to match brand
    outline: 5px auto colors.$linkColor;
  }
}

::selection {
  color: #fff;
  background: colors.$textHighlightColor; /* WebKit/Blink Browsers */
}
::-moz-selection {
  color: #fff;
  background: colors.$textHighlightColor; /* Gecko Browsers */
}
