@use 'src/sass/mixins';
@use 'src/sass/typography';

.actions {
  margin: 2.5rem 0 0;
}

.buttonLink {
  @include mixins.linkReset;
  @include mixins.buttonAppearance;

  max-width: 327px;
  margin: 0 auto 2rem;
}

.shoppingLink {
  @include mixins.linkAppearance;
  @include typography.medium(16px, 24px);
}
