@use 'src/sass/variables';

.continueButtonContainer {
  background: #fff;
  border-top: 1px solid #dfe0eb;
  z-index: 1;
  bottom: 0;
  width: 100%;
  position: sticky;

  @media (min-width: variables.$stepperMobileBreakpoint + 1) {
    height: variables.$floatingHeaderHeight;
  }
  @media (max-width: variables.$stepperMobileBreakpoint) {
    height: variables.$floatingHeaderHeightMobile;
  }
}

.continueBottomWrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}
