@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/typography';

.container {
  background: white;

  @include mediaQueries.forTabletPortraitUp {
    display: flex;
    flex-flow: row nowrap;
  }

  @include mediaQueries.forPhoneOnly {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flexbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.title {
  @include typography.bold(21px, 31px);

  color: colors.$rbSecondary;
  text-align: center;
  margin: 0 0 1.75rem;
}

.logo {
  max-height: 68px;
  display: block;
  margin: 0 auto 1.5rem;
}
.logoWithoutMarginBottom {
  max-height: 68px;
  display: block;
  margin: 0 auto 0;
}

.content {
  flex: 1;
  padding: 0 1.375rem;
  align-self: center;

  @include mediaQueries.forTabletPortraitUp {
    flex: 0 1 375px;
  }
}

.storeBackground {
  display: none;

  @include mediaQueries.forTabletPortraitUp {
    display: initial;
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: fixed;
    background-image: var(--background-image-url);
  }
}
