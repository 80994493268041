@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';

.footer {
  background: #f9f9fa;
  padding: 1.85rem 0 4.55rem;
  color: colors.$textColorDefault;
  flex-shrink: 0;

  .storeName {
    @include typography.regular(20px, 20px);
    letter-spacing: -0.02em;
    user-select: none;
    margin: 0 0 0.8rem;

    &.noLogo {
      margin: 0 0 1.3rem;
    }
  }
}

.logo {
  max-height: 24px;
  max-width: 136px;
}

.rbLogo {
  max-height: 68px;
}

.footerContent {
  max-width: 900px;
  margin: 0 auto;

  @media (max-width: 619px) {
    padding: 0 1.5rem;
    margin: 0 auto;

    & > div:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  @media (min-width: 620px) {
    display: flex;
    flex-flow: row nowrap;

    & > div {
      flex: 1 1 320px;

      &:not(:last-child) {
        margin-left: 2rem;
      }
    }
  }
}

.poweredByReturnBear {
  display: flex;
  align-items: center;
  user-select: none;
  margin: 0 0 0.7rem;
}

.footerLinkWrapper {
  margin: 0 0 0.75rem;
  @include typography.regular(12px, 16px);
}

.footerLink {
  display: inline-flex;
  color: #1b1c22;
}

.returnsPartner {
  margin-top: 0.25rem;
  color: #595b69;
}

.footerLinkArrow {
  color: #757784;
}
