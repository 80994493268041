@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/typography';

.container {
  padding: 1rem 0 1.5rem;
  margin: 0 0 2rem;
  border-bottom: 1px solid colors.$inputBorderColor;

  &:first-child {
    padding-top: 0;
  }

  @include mediaQueries.forTabletPortraitUp {
    display: flex;
    flex-flow: row nowrap;
    border: none;
    margin: 0 0 1rem;
    padding: 1rem 0;
  }
}

.title {
  @include typography.medium(18px, 24px);
  margin: 0 0 0.5rem;

  @include mediaQueries.forTabletPortraitUp {
    flex: 0 1 200px;
    margin: 0 1rem 0 0;
  }
}

.content {
  margin: 0 0 1rem;

  @include mediaQueries.forTabletPortraitUp {
    flex: 1 1 320px;
    margin: 0;
  }
}

.action {
  @include mediaQueries.forTabletPortraitUp {
    flex: 1 1 100px;
    text-align: right;
  }

  a {
    color: colors.$linkColor;
    @include typography.medium(16px, 16px);
    text-decoration: none;
  }
}
