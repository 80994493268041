@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';
@use 'src/sass/variables';

.container {
  display: flex; // using flex here to disable margin collapse
  flex-direction: row nowrap;
  align-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #dfe0eb;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  height: 4rem;

  @include mediaQueries.forPhoneOnly {
    height: 3rem;
  }
}

.header {
  @include typography.medium(24px, 36px);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  color: colors.$rbSecondary;

  @include mediaQueries.forPhoneOnly {
    @include typography.medium(14px, 18px);
    padding-left: 8px;
  }
}

.header img {
  max-height: 50px;
}

.title {
  @include typography.medium(24px, 36px);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  color: colors.$rbSecondary;

  @include mediaQueries.forPhoneOnly {
    font-size: 14px;
  }
}

.backToShopping {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mediaQueries.forPhoneOnly {
    padding-right: 4px;
  }
}

.backToShoppingButton {
  @include mixins.linkReset;
  @include mixins.buttonAppearance;
  min-width: 200px;

  @include mediaQueries.forPhoneOnly {
    height: 36px;
    min-width: 100px;
    font-size: 0.625rem;
    line-height: 0.75rem;
  }
}
