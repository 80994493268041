@use 'src/sass/colors';
@use 'src/sass/mixins';
@use 'src/sass/typography';

$smallViewportBreakpoint: 620px;

.container {
  position: relative;
  display: inline-flex;
  flex-flow: column nowrap;
}

.button {
  @include mixins.buttonReset;

  display: inline-flex;
  padding: 0.25rem 0.5rem;
  text-align: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  color: colors.$greySubInfo;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px colors.$inputFocusedColor;
  }
}

.tooltip {
  position: absolute;
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%) translateY(20px);
  opacity: 0;
  transition: 0.2s transform ease-out, 0.3s opacity ease-in;
  z-index: 2;
  pointer-events: none;
  user-select: none;

  @media (max-width: $smallViewportBreakpoint) {
    transform: translateY(20px);
    left: initial;
    right: 0;
    margin-right: -0.5rem;
  }

  &.visible {
    opacity: 1;
    transform: translateX(-50%) translateY(0);

    @media (max-width: $smallViewportBreakpoint) {
      transform: none;
    }
  }
}

.tooltipCenterRight {
  position: absolute;
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%) translateY(20px);
  opacity: 0;
  transition: 0.2s transform ease-out, 0.3s opacity ease-in;
  z-index: 2;
  pointer-events: none;
  user-select: none;

  @media (max-width: 850px) {
    transform: none;
    left: -25%;
  }

  @media (max-width: $smallViewportBreakpoint) {
    transform: none;
  }

  &.visible {
    opacity: 1;
    transform: translateX(-50%) translateY(0);

    @media (max-width: 850px) {
      transform: none;
    }

    @media (max-width: $smallViewportBreakpoint) {
      transform: none;
      left: -25%;
    }
  }
}

.tooltipArrow {
  display: block;
  margin: 0 auto;

  @media (max-width: $smallViewportBreakpoint) {
    margin-top: 8px;
  }
}

.tooltipArrowCenterRight {
  display: block;
  margin: 0 auto;

  @media (max-width: 850px) {
    margin: 0 0 0 0.5rem;
  }

  @media (max-width: $smallViewportBreakpoint) {
    margin: 0 0 0 0.5rem;
  }
}

.tooltipContent {
  @include typography.medium(12px, 16px);
  width: 240px;
  padding: 1rem;
  border-radius: 0.5rem;
  background: colors.$rbSecondary;
  color: #fff;
}

.icon {
  display: flex;
}

.message {
  padding-left: 8px;
  display: flex;
  text-align: left;
  @include typography.medium(12px, 16px);
  color: colors.$greySubInfo;
}
