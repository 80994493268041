@use 'src/sass/colors';
@use 'src/sass/typography';

%baseStepperItemStyle {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  color: colors.$greySubInfo;
  cursor: default;
  white-space: pre-line;
  border-bottom: 2px solid transparent;
  height: 100%;
}

.stepperItem {
  @extend %baseStepperItemStyle;
}

.stepperItemActive {
  @extend .stepperItem;

  border-color: colors.$brandPrimaryColor;
  color: colors.$rbSecondary;

  .prefix {
    background: colors.$brandPrimaryColor;
    color: #fff;
    box-shadow: none;
  }
}

.prefix {
  $prefixSize: 23px;

  height: $prefixSize;
  width: $prefixSize;
  line-height: $prefixSize;
  min-width: $prefixSize;
  text-align: center;
  vertical-align: center;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: $prefixSize;
  margin-right: 10px;
}

.stepperLink {
  @extend %baseStepperItemStyle;

  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: colors.$rbSecondary;
  }
}

.stepperLinkActive {
  @extend .stepperLink;

  color: colors.$rbSecondary;
  font-weight: 600;
  display: inline-flex;
  cursor: default;
}

.activeIcon {
  color: colors.$mainBlue;
  margin-right: 0.25rem;
  height: 20px;
  align-self: center;
}

.completedIcon {
  color: colors.$brandPrimaryColor;
}
