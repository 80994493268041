@use 'src/sass/colors';

.container {
  display: flex;
  flex-flow: row nowrap;
  padding: 1.5rem;
  border: 1px solid colors.$inputBorderColor;
  border-radius: 10px;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.checkbox,
.productThumbnail {
  margin-right: 1rem;
}

.image {
  border-radius: 0.5rem;
}

.productDetails {
  flex: 1;
  display: flex;
}

.productInfo {
  display: flex;
  flex-flow: column nowrap;
}

.productName {
  margin: 0 0 0.25rem;
}

.price {
  margin-left: auto;
  padding-left: 1rem;
}
