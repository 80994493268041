@use 'src/sass/colors';

.container {
  display: flex;
  flex-flow: row nowrap;
}

.thumbnailWrapper {
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid colors.$inputBorderColor;

  img {
    border-radius: inherit;
    width: 100%;
  }
}

.content {
  margin-left: 1rem;
  flex: 1;
}
