// Color Palette
$mainBlue: #5779d9;
$darkPurple: #2b1d69;
$pale: #f4ede9;
$darkPale: #cfb8ad;
$teal: #3c84ac;
$blue: #465db4;
$darkBlue: #2f3386;
$lightBlue: #ededfe;
$yellow: #f6c86d;
$green: #2e7d32;
$white: #fff;
$red: #cd281d;
$greySubInfo: #6a7281;
$disabled: #a8a8a8;

$brand600: #6946be;

$neutral900: #1b1c22;
$neutral600: #757784;
$neutral500: #757783;

// Not in the pallette yet, but named in Figma and used in multiple places
$rbPrimary: #5779d9;
$rbSecondary: #3a4353;
$textColorDark: #303030;

// UI colors
// Each UI element will have a named variable for a color name
// - components should avoid directly referencing colors from the palette, and should instead use the UI element variable
$appBackgroundColor: $darkPurple;
$brandPrimaryColor: $mainBlue;
$logoColor: #6650ab;
$errorColor: $red;
$linkColor: $rbPrimary;
$textColorDefault: $rbSecondary;
$textHighlightColor: $mainBlue;
$lightSuccessMain: #2e7d32;

// Inputs
$inputBorderColor: #dfe0eb; // s/b palette color
$inputHoverBorderColor: $rbSecondary;
$inputLabelColor: $greySubInfo;
$inputFocusedColor: $mainBlue;
$inputErrorColor: $errorColor;

// Buttons
$buttonColorDefault: $rbSecondary;
$buttonTextColor: $white;
$buttonLightBackgroundColor: #eee;
