@use 'src/sass/colors';
@use 'src/sass/mixins';
@use 'src/sass/typography';
@use 'src/sass/variables';

.container {
  position: relative;
  margin: 0.75rem 0 1.5rem;
  width: 100%;
  user-select: none;
  display: flex;
}
.buttonContainer {
  flex-direction: column;
  display: flex;
}

.label {
  @include typography.medium(12px, 18px);
  color: colors.$greySubInfo;
  background: #fff;
  white-space: pre;
  display: inline-flex;
  position: absolute;
  top: -0.5rem;
  left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
}

.button {
  @include mixins.buttonReset;
  @include typography.regular(16px, 24px);

  width: 100%;
  max-width: variables.$siteMaxWidth;
  flex: 1 1 100%;
  color: colors.$rbSecondary;
  background: #fff;
  padding: 0 1rem;
  height: 48px;
  border: 1px solid #dfe0eb;
  border-radius: 0.25rem;
  display: block;
  text-align: left;
  cursor: pointer;

  &:focus {
    @include mixins.inputFocusAppearance;
  }
}

.currentValue {
  width: 100%;
  display: flex;
  margin-right: 0.5rem;
}

.chevronDownIcon {
  margin-left: auto;
  align-self: center;
  pointer-events: none;
  color: colors.$rbSecondary;
  height: 10px;
  width: 10px;
}

.error {
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 0.25rem 0.5rem;
  color: colors.$errorColor;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #dfe0eb;
  border-radius: 4px;
  list-style: none;
  z-index: 2;
  opacity: 0;
  overflow-y: auto;
  max-height: 40vh;

  &.visible {
    padding: 0.5rem 0;
    opacity: 1;
  }

  &:focus {
    @include mixins.inputFocusAppearance;
  }
}

.buttonDisabled {
  pointer-events: none;
  border-color: colors.$disabled;
}
.buttonError {
  border-color: colors.$errorColor;
}
.dropDownDisabled {
  pointer-events: none;
}

.dropdownItem {
  @include typography.regular(14px, 21px);
  color: colors.$rbSecondary;
  padding: 0.25rem 1.5rem;
  margin: 0 0 0.25rem;
  cursor: pointer;
}

.dropdownItemActive {
  background-color: #bde4ff;
}

.lineItemDisabled {
  pointer-events: none;
  text-decoration: line-through;
  color: colors.$greySubInfo;
}

.containerDisabled {
  pointer-events: none;
}
.buttonText {
  width: 0;
  text-overflow: ellipsis;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
}
