@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';
@use 'src/sass/variables';

.container {
  display: flex; // using flex here to disable margin collapse
  flex-direction: column;
  margin-top: calc(variables.$floatingHeaderHeight + 0.5rem);
  padding-top: 16px;

  @media (min-width: variables.$stepperMobileBreakpoint + 1) {
    margin-bottom: 6rem;
  }

  @include mediaQueries.forPhoneOnly {
    margin-top: variables.$floatingHeaderHeight;
  }
}

.topStepper {
  background: #fff;
  border-bottom: 1px solid #dfe0eb;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  height: variables.$floatingHeaderHeightMobile;

  @media (min-width: variables.$stepperMobileBreakpoint + 1) {
    height: variables.$floatingHeaderHeight;
  }
}

.title {
  @include typography.regular(30px, 40px);

  color: colors.$rbSecondary;
  margin: 1rem;

  @include mediaQueries.forTabletPortraitUp {
    text-align: center;
    margin: 0 1rem 0.25rem;
  }

  @include mediaQueries.forPhoneOnly {
    margin: 1rem 0.5rem;
  }

  &:first-child {
    margin-top: 2.5rem;
  }
}

.subtitle {
  @include typography.regular(16px, 20px);

  color: colors.$rbSecondary;
  margin: 1rem;

  @include mediaQueries.forTabletPortraitUp {
    text-align: center;
    margin: 0 1rem 0.25rem;
  }

  @include mediaQueries.forPhoneOnly {
    margin: 0rem 0.5rem;
  }

  &:first-child {
    margin-top: 2.5rem;
  }
}

.content {
  @include mixins.boxAppearance;

  @include mediaQueries.forTabletPortraitUp {
    padding: 2.5rem 1.5rem;
  }

  @include mediaQueries.forPhoneOnly {
    padding: 0.5rem;
  }
}
