@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/typography';
@use 'src/sass/variables';

@mixin boxAppearance($maxWidth: variables.$siteMaxWidth, $sidePadding: 1.5rem) {
  width: 100%;
  max-width: calc($maxWidth + ($sidePadding * 2));
  padding: 0 $sidePadding;
  margin: 0 auto;
}

@mixin buttonAppearance {
  @include typography.medium(14px, 21px);
  height: 3rem;
  text-align: center;
  color: var(--button-text-color, colors.$buttonTextColor);
  background: var(--button-background-color, colors.$buttonColorDefault);
  border: none;
  border-radius: 1.5rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s all;
  user-select: none;

  &:focus {
    box-shadow: inset 0 0 0 2px colors.$inputFocusedColor;
  }

  &:disabled {
    background: #a8a8a8;
    color: #fff;
    cursor: not-allowed;
  }
}

@mixin buttonReset {
  appearance: none;
  background: transparent;
  border: none;
}

@mixin inputReset {
  appearance: none;
  background: transparent;
  border: none;
}

@mixin visuallyHidden {
  // https://css-tricks.com/inclusively-hidden/
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin inputFocusAppearance {
  outline: 5px auto colors.$inputFocusedColor;
}

@mixin linkReset {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

@mixin linkAppearance {
  color: colors.$linkColor;
  text-decoration: none;
  cursor: pointer;
}

@mixin rwd($propertyName, $defaultValue, $mediaQueries: ()) {
  #{$propertyName}: #{$defaultValue};

  @each $breakpoint, $value in $mediaQueries {
    @media (min-width: #{$breakpoint}) {
      #{$propertyName}: #{$value};
    }
  }
}

/**
 * Truncates the current line and makes text trail off at the end of the current line with an ellipsis
 */
@mixin truncateLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin numberedListAppearance {
  counter-reset: numberedListCounter;
  list-style: none;
  margin: 0;
  padding: 2.5rem 0;
  color: colors.$rbSecondary;
  @include typography.light(16px, 24px);

  li {
    counter-increment: numberedListCounter;
    position: relative;
    padding-left: 3rem;
    margin: 0 0 2.5rem;

    @include mediaQueries.forTabletPortraitUp {
      padding-left: 3.5rem;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
      background: #fbfbfb;
      border: 1px solid colors.$mainBlue;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      content: counter(numberedListCounter);

      @include mediaQueries.forTabletPortraitUp {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
}
