@use 'src/sass/mixins';
@use 'src/sass/typography';

.shippingLabelButton {
  @include mixins.buttonReset;
  @include mixins.buttonAppearance;
  @include mixins.linkReset;

  max-width: 327px;
  width: 100%;
  margin: 1.5rem auto 2rem;
}

.actions {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  align-items: center;
}

.backToShopping {
  @include mixins.linkReset;
  @include mixins.buttonAppearance;

  min-width: 327px;
  margin: 0 0 2.5rem;
}

.createAnotherReturn {
  @include mixins.linkAppearance;
  @include typography.bold(16px, 24px);
}
