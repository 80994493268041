@use 'src/sass/colors';
@use 'src/sass/mixins';
@use 'src/sass/typography';

.container {
  position: relative;

  & > label {
    display: flex;
  }
}

.button {
  @include mixins.buttonReset;

  color: colors.$linkColor;
  @include typography.semibold(16px, 24px);
  cursor: pointer;
}

.label {
  @include typography.medium(12px, 18px);
  color: colors.$greySubInfo;
  background: #fff;
  white-space: pre;
  display: inline-flex;
  position: absolute;
  top: -0.5rem;
  left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
}

.textarea {
  @include typography.regular(16px, 24px);
  color: colors.$greySubInfo;
  background: #ffffff;
  border: 1px solid colors.$inputBorderColor;
  border-radius: 0.5rem;
  flex: 1;
  height: 7rem;
  min-height: 3.5rem;
  resize: vertical;
  padding: 1rem;

  &:focus {
    outline: 5px auto colors.$inputFocusedColor;
  }
}
