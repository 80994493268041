@use 'src/sass/typography';
@use 'src/sass/mixins';
@use 'src/sass/mediaQueries';

.alertContainer {
  background: #e6f3fa;
  border-radius: 4px;
  padding: 15px;
  font-size: 14px;
  line-height: 20px;
}

.alertContainerIcon {
  margin-right: 14px;
  justify-content: center;
}

.alertContainerText {
  @include typography.regular(14px, 20px);

  // the "ReactMarkdown" component creates additional margins
  p, h1, h2, h3, h4, h5, ul {
    margin: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.alertUrlContainer {
  justify-content: right;
  width: 97%; // Necessary for the correct display of text and icon(s)
  @include mediaQueries.forPhoneOnly {
    padding-top: 8px;
    justify-content: left;
  }

  svg {
    margin-left: 6px;
  }
}



.url {
  @include mixins.linkAppearance;
}
