@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';

$sections: checkbox, productThumb, productInfo, returnReason, resolutionArea;

.container {
  padding: 1rem;
  border: 1px solid colors.$inputBorderColor;
  border-radius: 10px;

  display: grid;
  grid-template-areas:
    'checkbox productThumb productInfo'
    'returnReason returnReason returnReason'
    'resolutionArea resolutionArea resolutionArea';

  grid-template-rows: repeat(3, min-content);
  grid-template-columns: fit-content(1rem) fit-content(4rem) 1fr;

  @include mediaQueries.forTabletPortraitUp {
    grid-template-areas:
      'checkbox productThumb productInfo'
      'returnReason returnReason returnReason'
      'resolutionArea resolutionArea resolutionArea';
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.isDisabled {
    background: #fafbfb;
  }

  &.selectable {
    cursor: pointer;

    &:hover {
      background: #fbfbfe;
    }
  }

  &.selected {
    box-shadow: inset 0 0 0 2px colors.$mainBlue;
    border-color: colors.$mainBlue;
    background: #fff;
    cursor: default;
  }
}

@each $section in $sections {
  .#{$section} {
    grid-area: $section;
  }
}

.checkbox {
  margin-right: 0.5rem;

  @include mediaQueries.forTabletPortraitUp {
    margin-right: 1rem;
  }
}

.returnReason {
  margin-top: 0.5rem;

  &:focus {
    outline: none;
  }
}

.productThumb {
  margin-right: 1rem;
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  height: 55px;
  width: 55px;
  border: 1px solid colors.$inputBorderColor;

  img {
    border-radius: inherit;
    width: 100%;
  }

  &.isDisabled {
    opacity: 0.5;
  }
}

.productInfo {
  display: flex;
  flex-flow: row nowrap;
}

.productDetails {
  margin-right: 1rem;
}

.productName {
  @include typography.medium(16px, 28px);

  margin: 0 0 1px;
  color: colors.$textColorDark;
}

.variantInfo {
  @include typography.regular(14px, 21px);
  margin: 0 0 1rem;
}

.price {
  @include typography.semibold(16px, 28px);

  margin-left: auto;
}

.criteriaCheckbox {
  padding: 11px 10px 10px 18px
}

.resolutionArea {
  margin-top: 1.5rem;
}

.differentSizeOrColor {
  @include typography.semibold(16px, 28px);
  color: colors.$rbSecondary;
  margin: 1rem 0 0;
}

.finalSaleNotice {
  @include typography.medium(14px, 20px);
  color: colors.$greySubInfo;
  margin: 0 0 1rem;
}

.switchToReturn {
  margin-top: 1.5rem;
  @include typography.medium(14px, 20px);
  color: colors.$greySubInfo;
}

.returnInfoReturnOnly {
  margin: 1.5rem 0 -0.5rem;
  @include typography.regular(16px, 24px);
  color: colors.$greySubInfo;
}
.returnInfo {
  margin: 1.5rem 0 2rem;
  @include typography.regular(16px, 24px);
  color: colors.$greySubInfo;
}

.returnLinkButton {
  @include mixins.buttonReset;
  @include mixins.linkAppearance;

  display: inline-block;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.exchangeProduct {
  @include mediaQueries.forTabletPortraitUp {
    display: flex;
    flex-flow: nowrap;
  }
}

.exchangeOptions {
  flex: 1;

  @include mediaQueries.forTabletPortraitUp {
    margin-left: 1rem;
  }
}

.divider {
  border-bottom: 1px solid colors.$inputBorderColor;
  flex: 1 1;
  margin: 1rem 0 1rem;
}

.feeContainer {
  flex: 1;
  display: flex;
}

.feeAmount {
  margin-left: auto;
  align-items: center;
  text-align: right;
  color: #6a7281;
  @include typography.regular(16px, 28px);
}

.feeName {
  @include typography.regular(16px, 24px);
  color: colors.$greySubInfo;
}

.errorMessage {
  @include typography.regular(16px, 28px);
  color: colors.$errorColor;
}
