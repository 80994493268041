@use 'src/sass/colors';
@use 'src/sass/mixins';

.container {
    @include mixins.buttonAppearance;

  &.link {
    @include mixins.buttonReset;
    @include mixins.linkAppearance;

    color: colors.$linkColor;
  }

  &.text {
    color: var(--button-background-color, colors.$buttonColorDefault);
    background-color: transparent;
  }
}

.loadingIndicator {
  margin-right: 0.5rem;
}
