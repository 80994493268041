@use 'src/sass/colors';

.container {
  border: 2px solid #ccc;
  border: 1px solid #dfe0eb;
  border-radius: 10px;
  user-select: none;

  &.selected {
    box-shadow: inset 0 0 0 2px colors.$mainBlue;
    border-color: colors.$mainBlue;
    background: #fff;
    cursor: default;
  }
}

.selectable {
  cursor: pointer;

  &:hover,
  &:focus {
    &:not(.selected) {
      background: #fbfbfe;
    }
  }

  &:focus {
    outline: none;
    border-color: colors.$mainBlue;
  }
}
