@mixin forPhoneOnly {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin forTabletPortraitUp {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin forTabletLandscapeUp {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin forDesktopUp {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin forBigDesktopUp {
  @media (min-width: 1600px) {
    @content;
  }
}
