@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/typography';

.payoutAmount {
  @include typography.light(16px, 28px);

  margin: -2.5rem 0 2rem;
  color: colors.$textColorDefault;

  @include mediaQueries.forPhoneOnly {
    margin-top: -0.5rem;
  }

  @include mediaQueries.forTabletPortraitUp {
    text-align: center;
  }

  strong {
    font-weight: 500;
  }
}

.paymentAmounts {
  @include typography.medium(16px, 28px);

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: -2.5rem 0 2rem;
  color: colors.$greySubInfo;

  @include mediaQueries.forPhoneOnly {
    margin: -0.5rem 0 0rem;
  }

  @include mediaQueries.forTabletPortraitUp {
    text-align: center;
    justify-content: center;
  }
}

.helpText {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  @include typography.medium(12px, 16px);
  color: colors.$greySubInfo;
}

.options {
  margin: 0 0 2.5rem;
}

.option {
  padding: 1.5rem;
  @include typography.regular(14px, 21px);
  display: flex;
  color: #757784;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.optionInfo {
  flex: 1;
}

.optionTitle {
  @include typography.semibold(16px, 28px);
  margin: 0 0 0.25rem;
  color: colors.$textColorDefault;
}

.bonusAmount {
  color: colors.$green;
  @include typography.semibold(16px, 28px);
}

.divider {
  border-bottom: 1px solid colors.$inputBorderColor;
  flex: 1 1;
  margin: 1rem 0 1rem;
}

.feeAmount {
  margin-left: auto;
  @include typography.regular(16px, 28px);
  align-items: center;
  text-align: right;
  color: #6a7281;
}

.optionContainer {
  margin: 0.25rem 0 0rem;
  flex: 1;
  display: flex;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.errorMessage {
  @include typography.regular(16px, 28px);
  color: colors.$errorColor;
}
