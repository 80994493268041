@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';

$sections: productThumb, productInfo, content;

.container {
  padding: 1rem;
  border: 1px solid colors.$inputBorderColor;
  border-radius: 10px;

  display: grid;
  grid-template-areas:
    'productThumb productInfo'
    'content content';

  grid-template-rows: repeat(2, min-content);
  grid-template-columns: fit-content(4rem) 1fr;

  @include mediaQueries.forTabletPortraitUp {
    grid-template-areas:
      'productThumb productInfo'
      'productThumb content';
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

@each $section in $sections {
  .#{$section} {
    grid-area: $section;
  }
}

.productThumb {
  $thumbnailSize: 55px;

  margin-right: 1rem;
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  height: $thumbnailSize;
  width: $thumbnailSize;

  img {
    border-radius: inherit;
    width: 100%;
  }
}

.productInfo {
  display: flex;
  flex-flow: row nowrap;
}

.productDetails {
  margin-right: 1rem;
}

.productName {
  @include typography.medium(16px, 28px);

  margin: 0 0 1px;
  color: colors.$textColorDark;
}

.variantInfo {
  @include typography.regular(14px, 21px);
}

.content {
  @include typography.regular(16px, 24px);

  color: colors.$greySubInfo;
}
