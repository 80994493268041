@use 'src/sass/mixins';
@use 'src/sass/mediaQueries';
@use 'src/sass/typography';

.actions {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  align-items: center;
}

.backToShopping {
  @include mixins.linkReset;
  @include mixins.buttonAppearance;

  min-width: 327px;
  margin: 0 0 2.5rem;
}

.createAnotherReturn {
  @include mixins.linkAppearance;
  @include typography.bold(16px, 24px);
}

@include mediaQueries.forPhoneOnly {
  // The order should be flipped on mobile
  .hero {
    display: flex;
    flex-flow: column-reverse;
  }
}

.qrCodeWrapper {
  margin: 2rem 0;
}
