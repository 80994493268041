@use 'src/sass/colors';
@use 'src/sass/typography';

.container {
  border-bottom: 1px solid colors.$inputBorderColor;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  transition: 0.2s color;

  & > label {
    display: block;
    width: inherit;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    border-bottom: 1px solid colors.$inputFocusedColor;
    transition: 0.2s width;
    transform: translateX(-50%);
  }

  &:focus-within {
    border-color: colors.$inputFocusedColor;

    &:after {
      width: 100%;
    }
  }
}

.inputLabel {
  @include typography.medium(14px, 28px);
  color: colors.$inputLabelColor;
  transition: 0.3s font, 0.2s transform, 0.3s color;
  color: colors.$inputFocusedColor;
  height: 16px;
  display: flex;
  align-items: center;

  &:not(.focused) {
    cursor: pointer;
  }

  &.shiftedDown {
    transform: translateY(1.5rem);
    @include typography.regular(16px, 24px);
  }

  &:not(.focused) {
    color: colors.$greySubInfo;
  }
}

.input {
  border: none;
  height: 2.5rem;
  width: 100%;
  @include typography.regular(16px, 24px);
  &:disabled {
    opacity: 0.32;
  }

  &:focus {
    outline: none;
    border-bottom-color: colors.$inputFocusedColor;
  }

  // Remove spinner arrows Chrome, Safari, Edge, Opera
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Remove spinner arrows Firefox
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.tooltip {
  margin-left: auto;
  align-self: flex-end;
  margin-bottom: 0.5rem;
}

.error {
  color: #d32f2f;
  font-size: 12px;
  line-height: 1rem;
  padding-bottom: 4px;
}
