@use 'src/sass/colors';

$dot-color: currentColor;
$dot-width: 10px;
$dot-radius: $dot-width * 0.5;
$dot-spacing: $dot-width + $dot-width * 0.5;

@import '~three-dots/sass/variables';
@import '~three-dots/sass/mixins';
@import '~three-dots/sass/dot-pulse';

.container {
  position: relative;
  padding-top: $dot-height;
}

.dots {
  @extend .dot-pulse;

  display: flex;
  justify-content: center;
  align-items: center;
  height: $dot-height;
  margin: 0 auto $dot-height;
  color: colors.$logoColor;
}
