@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';

$zIndexDialog: 25;
$dialogMaxWidth: 460px;

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 3.5rem;
  z-index: $zIndexDialog;

  &:focus {
    outline: none;
  }
}

.dialog {
  position: relative;
  background: #fff;
  max-width: $dialogMaxWidth;
  margin: 1rem auto 0;
  border-radius: 12px;
  display: flex;
  flex-flow: column nowrap;
  max-height: 90vh;

  @include mediaQueries.forTabletPortraitUp {
    margin-top: 2rem;
  }
}

.dialogContent {
  @include typography.light(16px, 28px);

  height: 100%;
  overflow-y: auto;
  padding: 0 1rem;
  margin: 0 0 2rem;
}

.title {
  @include typography.regular(24px, 32px);

  padding: 1.5rem 1rem;
}

.actions {
  padding: 1rem;
  display: flex;
  flex-flow: row nowrap;

  & > *:not(:first-child) {
    margin-left: 1rem;
  }

  & > button:only-child,
  & > a:only-child {
    min-width: 135px;
  }
}

.closeButton {
  @include mixins.buttonReset;

  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  z-index: $zIndexDialog + 1;
  color: #757784;
  cursor: pointer;

  &:hover {
    color: #0b0c0d;
  }

  &:active {
    transform: translateY(1px);
  }
}

.dialogBackdrop {
  background: #0b0c0d;
  opacity: 0.4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zIndexDialog - 1;
}
