@use 'src/sass/colors';
@use 'src/sass/mixins';
@use 'src/sass/typography';

.container {
  position: relative;
  display: inline-flex;
  user-select: none;
}

.button {
  @include mixins.buttonReset;
  @include typography.medium(12px, 16px);
  border: 1px solid rgba(23, 25, 38, 0.121569);
  border-radius: 4px;
  text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  padding: 0.5rem;
  display: flex;
  cursor: pointer;
  user-select: none;
  min-width: 230px;
  white-space: pre;

  &:hover {
    background: #fff;
  }

  strong {
    margin-left: 0.25rem;
    color: #000;
  }
}

.chevronDown {
  margin: 0 0.25rem 0 auto;
  opacity: 0.7;
  align-self: center;
  color: #000;
}

.dropdown {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  margin: 0 0 0.25rem;
  padding: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #dfe0eb;
  border-radius: 4px;
  list-style: none;
  z-index: 2;
  opacity: 0;
  max-height: 40vh;
  overflow-y: auto;

  &.visible {
    padding: 0.25rem 0;
    opacity: 1;
  }

  &:focus {
    @include mixins.inputFocusAppearance;
  }
}

.dropdownItem {
  @include typography.regular(12px, 21px);
  color: colors.$rbSecondary;
  padding: 0 1.5rem;
  margin: 0 0 0.25rem;
  cursor: pointer;
}

.dropdownItemActive {
  background-color: #bde4ff;
}
