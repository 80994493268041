@use 'src/sass/colors';
@use 'src/sass/mixins';

$checkboxSize: 20px;

.container {
  display: flex;
  position: relative;
}

.checkbox {
  @include mixins.visuallyHidden;
}

.hitArea {
  cursor: pointer;
  display: inline-flex;
  height: $checkboxSize;
  width: $checkboxSize;
  border: 1px solid #6a7281;
  border-radius: 0.25rem;
  background: #fff;
  color: #fff;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;

  svg {
    height: $checkboxSize * 0.65;
    width: $checkboxSize * 0.65;
  }
}

.checkbox:checked + .hitArea {
  background: colors.$rbSecondary;
  border-color: colors.$rbSecondary;
}

.checkbox:disabled + .hitArea {
  opacity: 0.2;
}

.checkbox:focus + .hitArea {
  @include mixins.inputFocusAppearance;
}

.label {
  margin-left: 0.5rem;
  flex: 1;
  cursor: pointer;
}
