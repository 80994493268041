$shimmerColor: #d5e1ff;

.container {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  border-radius: 3px;
  vertical-align: middle;
  max-width: 100%;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    $shimmerColor 8%,
    mix($shimmerColor, #fff, 0.25) 18%,
    $shimmerColor 33%
  );
  background-size: 800px 104px;
  position: relative;
  opacity: 0.32;

  &.dark {
    opacity: 0.64;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
