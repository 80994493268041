@use 'sass:list';
@use 'sass:math';

@function stripUnit($value) {
  @return math.div($value, $value * 0 + 1);
}

@function rem($pxValue) {
  @return #{math.div(stripUnit($pxValue), stripUnit($fontSizeDefault))}rem;
}

$fontFamilyStore: var(--font-family-default), sans-serif;
$fontFamilyStoreName: 'DM Serif Display', sans-serif;
$fontFamilySystem: sans-serif;

$fontFamilyDefault: $fontFamilyStore;

$fontSizeDefault: 16px;
$lineHeightDefault: 22px;

@mixin baseStyle(
  $fontSize: 16px,
  $lineHeight: 22px,
  $fontWeight: 400,
  $fontFamily: $fontFamilyDefault
) {
  font: #{$fontWeight} list.slash(rem($fontSize), rem($lineHeight)) $fontFamily;

  letter-spacing: 0.01em;
}

@mixin light($fontSize, $lineHeight, $args...) {
  @include baseStyle($fontSize, $lineHeight, 300, $args...);
}

// This is the default font weight
@mixin regular($fontSize, $lineHeight, $args...) {
  @include baseStyle($fontSize, $lineHeight, 400, $args...);
}

@mixin medium($fontSize, $lineHeight, $args...) {
  @include baseStyle($fontSize, $lineHeight, 500, $args...);
}

@mixin semibold($fontSize, $lineHeight, $args...) {
  @include baseStyle($fontSize, $lineHeight, 600, $args...);
}

@mixin bold($fontSize, $lineHeight, $args...) {
  @include baseStyle($fontSize, $lineHeight, 700, $args...);
}
