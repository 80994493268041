@use 'src/sass/colors';
@use 'src/sass/mixins';
@use 'src/sass/typography';
@use 'src/sass/mediaQueries';

.container {
  padding: 0 1rem;

  p {
    @include typography.regular(16px, 26px);

    margin: 0 0 1rem;
  }

  small {
    @include typography.regular(14px, 21px);

    display: block;
    margin: 2.5rem 0 1.5rem;
    color: #676565;
  }

  a {
    @include mixins.linkAppearance;
  }
}

.modal {
  max-width: 770px;
}

.checkboxes {
  margin: 0 0 1rem;
}

.checkbox {
  margin: 0 0 1rem;
  @include typography.regular(16px, 21px);
}

.placeholder {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 1rem;
}

.placeholderCheckbox {
  margin-right: 1rem;
}

.ctaButton {
  @include mediaQueries.forPhoneOnly {
    width: 100%;
  }
}
