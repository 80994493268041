.container {
  padding: 1rem;
}

.modal {
  max-width: 773px;
}

.input {
  margin: 0 0 1rem;
}

.splitRow {
  display: flex;
  flex-flow: row nowrap;

  .input {
    flex: 1;

    &:not(:first-child) {
      margin-left: 1rem;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.actions {
  margin: 2rem 0 0;
  display: flex;
  flex-flow: row nowrap;

  & > *:not(:first-child) {
    margin-left: 1rem;
  }
}

.continueButton {
  min-width: 135px;
}
