@use 'src/sass/variables';

.mainWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.contentArea {
  flex: 1 0 auto;
  position: relative;
}
