@use 'src/sass/colors';
@use 'src/sass/mixins';
@use 'src/sass/typography';

.container {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: stretch;

  border: 1px solid colors.$greySubInfo;
  background: #fff;
  color: colors.$greySubInfo;
  border-radius: 0.25rem;
  height: 52px;
}

.token {
  @include mixins.buttonReset;

  color: inherit;
  padding: 0 2rem;
  @include typography.semibold(16px, 24px);
  cursor: pointer;

  &:focus {
    @include mixins.inputFocusAppearance;
  }
}

.activeToken {
  @extend .token;

  background: colors.$rbSecondary;
  color: #fff;
  cursor: default;
}
