@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';

.container {
  @include mixins.boxAppearance;
  @include typography.light(16px, 24px);

  color: colors.$rbSecondary;
  padding: 1rem;

  @include mediaQueries.forTabletPortraitUp {
    text-align: center;
    padding: 5rem 0;
    margin-bottom: 6rem;
  }
}

.illustration {
  max-width: 120px;
  margin: 0 auto 1rem;
  display: block;

  @include mediaQueries.forTabletPortraitUp {
    max-width: 220px;
    margin: 0 auto 2.5rem;
  }
}

.title {
  @include typography.bold(24px, 36px);
}
