@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';

.locationsList {
  padding: 0.5rem 0rem;
  border-radius: 16px;
}

.location {
  display: flex;
  padding: 1rem;
  background-color: colors.$white;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.locationDropOffIcon {
  flex: 0 0 2.5rem;
  color: colors.$logoColor;
  border-radius: 50%;
  height: 2.5rem;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.isSelected {
    background: colors.$logoColor;
    color: #fff;
  }
}

.locationName {
  @include typography.regular(16px, 20px);
  margin: 0 0 0.2rem;
}

.locationInfo {
  @include typography.regular(12px, 16px);
  color: #1b1c22;
}

.locationAddress {
  @include typography.regular(16px, 20px);
  color: colors.$greySubInfo;
  padding-bottom: 0.5rem;
}

.locationAddressMobile {
  @include typography.regular(12px, 16px);

  color: colors.$greySubInfo;
  padding-bottom: 0.5rem;
}

.locationDistance {
  @include typography.regular(12px, 1rem);

  flex: 0 1 2rem;
  color: colors.$neutral600;
  white-space: pre;
  margin-left: auto;
  text-align: right;
}

.detailsRightSection {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: auto;
  padding-left: 0.5rem;
}

.arrowRightIcon {
  @include typography.regular(16px, 20px);

  color: colors.$neutral600;
  white-space: pre;
  margin-left: auto;
}

.linkSection {
  display: flex;
  align-items: center;
  text-align: center;
}

.mapButton {
  padding: 0 1rem 0 0;
  height: 28px;
  color: colors.$linkColor;
  background-color: transparent;
}

.mapLink {
  display: flex;
  padding-right: 1.5rem;
  align-items: center;
  justify-content: center;
}

.mapText {
  @include typography.medium(13px, 20px);

  display: flex;
  padding: 0 1rem 0 0.5rem;
}

.hoursOfOperationUrl {
  @include typography.medium(13px, 20px);
  color: colors.$linkColor;
}

.moreOptionsButtonArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.moreOptionsButton {
  @include typography.medium(15px, 26px);
  width: 100%;
  margin: 0 4rem;
  border: 1px solid colors.$linkColor;
  border-radius: 4px;
  color: colors.$linkColor;
  background-color: transparent;
}
