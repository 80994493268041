@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';

.option {
  padding: 1.5rem;
  @include typography.regular(14px, 21px);
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.rowContent {
  display: flex;
  flex-direction: row;
}

.mailInOption {
  padding: 1.5rem;
  @include typography.regular(14px, 21px);
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.optionContainer {
  flex: 1;
  display: flex;
}
.optionInfo {
  flex: 1;
}

.optionTitle {
  @include typography.medium(16px, 28px);
  color: colors.$neutral900;
  margin: 0 0 0.25rem;
}

.errorMessage {
  @include typography.regular(16px, 28px);
  color: colors.$errorColor;
  margin: 0.5rem 0 0.5rem;
}

.optionBorder {
  border-bottom: 1px solid colors.$inputBorderColor;
  flex: 1 1;
  margin: 1rem 0 1rem;
}

.price {
  margin-left: auto;
  @include typography.regular(16px, 28px);
  align-items: center;
  text-align: right;
  color: #6a7281;
}

.freePrice {
  font-weight: 500;
  color: colors.$lightSuccessMain;
}

.dropOffLocation {
  overflow: hidden;
  border: 1px solid colors.$inputBorderColor;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0.5rem 0 1rem;
  display: flex;
  flex-flow: row nowrap;
  padding: 1rem;

  a {
    @include mixins.linkAppearance;
    @include typography.medium(12px, 16px);
    display: inline-flex;
    align-items: self-end;
  }
}

.dropOffIcon {
  flex: 0 0 2.6rem;
  background: colors.$logoColor;
  color: #fff;
  height: 2.6rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.dropOffDetails {
  flex: 1 1;
  @include typography.medium(14px, 20px);
  margin-right: 1rem;

  strong {
    font-weight: 500;
  }
}

.locationInfo {
  margin: 0 0 0.5rem;

  @include mediaQueries.forTabletPortraitUp {
    display: flex;
  }
}

.externalLinkIcon {
  margin-left: 0.25rem;
}

.otherLocation {
  margin: 1rem 0 0;
  margin-bottom: 16px;
}

.changeLocationButton {
  @include typography.medium(13px, 22px);
  @include mixins.linkAppearance;
  padding: 3px 9px 2px 9px;
  background: transparent;
  border: 1px solid colors.$mainBlue;
  border-radius: 4px;
}

.address {
  font-weight: 400;
  color: #757784;

  strong {
    display: block;
    color: #1b1c22;
  }
}

.distance {
  @include typography.regular(14px, 20px);
  color: #757784;
  white-space: pre;

  @include mediaQueries.forTabletPortraitUp {
    margin-left: auto;
  }
}

.helpText {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  @include typography.medium(12px, 16px);
  color: colors.$greySubInfo;
}

.shipsFrom {
  margin: 1.5rem 0 0;
  @include typography.regular(14px, 20px);
  color: #757784;
}

.changeAddressButton {
  @include mixins.buttonReset;
  @include mixins.linkAppearance;
  @include typography.medium(14px, 16px);
  padding: 0;
  margin: 0 0.25rem;
}

.threeDots {
  flex: 0 0 3rem;
  margin-right: 1rem;
}

.flagIcon {
  vertical-align: baseline;
  margin-right: 0.5rem;
}
.flagContainer {
  display: flex;
  align-items: center;
}
.infoBox {
  background: #e6f3fa;
  border-radius: 10px;
  padding: 15px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.alertWrapper {
  margin-bottom: 20px;
}
