@use 'src/sass/colors';
@use 'src/sass/mixins';
@use 'src/sass/typography';
@use 'src/sass/mediaQueries';

.modalOverrides {
  @include mediaQueries.forTabletPortraitUp {
    margin-top: 2rem;
    max-width: 70%;
  }

  @include mediaQueries.forDesktopUp {
    margin-top: 2rem;
    max-width: 50%;
  }

  @include mediaQueries.forBigDesktopUp {
    margin-top: 2rem;
    max-width: 35%;
  }
}

.titleOverrides {
  font-size: 1.3125rem;
}

.globalErrorsText {
  @include typography.regular(14px, 28px);

  color: colors.$errorColor;
  margin: 0 0 1.5rem;
}

.readOnlyWrapper {
  padding: 5px 20px;
  line-height: 12px;
  font-size: 1.125rem;
}

.mainWrapper {
  padding: 5px 20px 20px 20px;
  line-height: 36px;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.leftWrapper {
  width: 50%;
}

.selectRightWrapper {
  left: 51%;
  width: 46%;
  position: absolute;
}

.selectLeftWrapper {
  width: 46%;
  position: absolute;
}

.input {
  margin-bottom: 10px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
