@use 'src/sass/variables';

.fullWidthStepper {
  height: variables.$floatingHeaderHeight;

  @media (max-width: variables.$stepperMobileBreakpoint) {
    display: none;
  }
}

.fullWidthStepperWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  height: inherit;
  position: relative;
  max-width: variables.$siteMaxWidth;
  margin: 0 auto;
}

.itemsSelected {
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  display: flex;
}

.mobileStepper {
  padding: 1rem;
  user-select: none;
  display: flex;

  @media (min-width: (variables.$stepperMobileBreakpoint + 1)) {
    display: none;
  }

  .continueButton {
    margin-left: auto;
  }
}

.continueButton {
  @media (min-width: (variables.$stepperMobileBreakpoint + 1)) {
    align-self: center;
  }

  & > button {
    max-height: 32px;
  }
}
