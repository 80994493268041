@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';

.container {
  @include mixins.boxAppearance;
  @include typography.light(16px, 24px);

  color: colors.$rbSecondary;
  padding: 1rem;

  @include mediaQueries.forTabletPortraitUp {
    text-align: center;
    padding: 6rem 2.5rem;
    margin-bottom: 6rem;
  }

  @include mediaQueries.forPhoneOnly {
    padding: 6rem 2.5rem;
  }

  ol {
    @include mixins.numberedListAppearance;
  }

  h3 {
    font-weight: 500;
    margin: 0 0 0.25rem;
  }
}

.title {
  @include typography.medium(24px, 36px);

  margin: 0 0 0.5rem;

  @include mediaQueries.forPhoneOnly {
    text-align: center;
  }
}

.subTitle {
  @include typography.light(16px, 24px);

  margin: 0 0 2.5rem;
}

.hero {
  text-align: center;
  margin: 0 0 2.5rem;

  @include mediaQueries.forTabletPortraitUp {
    background: #fbfbfb;
    border: 1px solid colors.$brandPrimaryColor;
    border-radius: 10px;
    padding: 1rem;
  }

  h2 {
    @include typography.medium(18px, 24px);
    margin: 0 0 0.5rem;
    color: colors.$rbSecondary;
  }

  p {
    @include typography.light(16px, 24px);
    max-width: 460px;
    margin: 0 auto;
  }
}

.nextSteps {
  counter-reset: nextStepCounter;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 2.5rem;

  @include mediaQueries.forPhoneOnly {
    display: none;
  }

  li {
    @include typography.regular(16px, 24px);

    color: colors.$rbSecondary;
    flex: 1;
    counter-increment: nextStepCounter;
    padding-top: 3.5rem;
    position: relative;

    &:before {
      @include typography.bold(16px, 28px);

      content: counter(nextStepCounter);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 2.5rem;
      width: 2.5rem;
      background: #fff;
      color: colors.$brandPrimaryColor;
      border: 2px solid colors.$brandPrimaryColor;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      top: 1.25rem;
      left: 0;
      width: 100%;
      z-index: 1;
      border-bottom: 2px solid colors.$brandPrimaryColor;
    }

    &:first-child {
      &:before {
        background: colors.$brandPrimaryColor;
        color: #fff;
      }

      &:after {
        left: 50%;
      }
    }

    &:last-child:after {
      left: initial;
      right: 50%;
    }
  }
}

.content {
  border-top: 1px solid colors.$inputBorderColor;
  text-align: left;
}

.actions {
  margin: 2.5rem 0 6rem;

  .backToShopping {
    @include mixins.linkReset;
    @include mixins.buttonAppearance;

    margin: 0 auto;
    width: 327px;
    max-width: 100%;
  }
}
