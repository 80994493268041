@use 'src/sass/colors';
@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';

$mobileMapHeight: 250px;

.modalOverrides {
  @include mediaQueries.forPhoneOnly {
    margin: auto;
    max-height: 100%;
    border-radius: 0px;
  }
}

.modalTitleOverrides {
  @include mediaQueries.forPhoneOnly {
    @include typography.medium(20px, 24px);
    padding: 1.5rem 2.5rem 0.6rem 0.6rem;
  }
}

.modalCloseButtonOverrides {
  @include mediaQueries.forPhoneOnly {
    position: absolute;
    top: 1.2rem;
    right: 0.8rem;
  }
}

.content {
  border: 1px solid rgba(23, 25, 38, 0.121569);
  display: flex;
  flex-flow: column-reverse nowrap;

  @include mediaQueries.forTabletPortraitUp {
    flex-flow: row nowrap;
    max-height: 80vh;
  }
}

.locationsList {
  padding: 0.5rem 0.5rem;
  border-radius: 16px;
  z-index: 9999;

  @include mediaQueries.forTabletPortraitUp {
    width: 400px;
    max-height: 55vh;
    overflow-y: auto;
  }
}

.map {
  flex: 1 1 $mobileMapHeight;
  @include mediaQueries.forTabletPortraitUp {
    flex-basis: 1;
  }
  @include mediaQueries.forPhoneOnly {
    // svh works with safari and other browsers, vh doesnt
    // https://stackoverflow.com/a/72245072/13637417
    min-height: calc(100svh - 135px);
  }
}

.location {
  display: flex;
  padding: 1rem;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.locationName {
  @include typography.regular(16px, 20px);
  margin: 0 0 0.2rem;
}

.locationInfo {
  @include typography.regular(12px, 18px);
  color: #1b1c22;
}

.address {
  color: colors.$greySubInfo;
}

.dropOffIcon {
  flex: 0 0 2.6rem;
  color: colors.$logoColor;
  border-radius: 50%;
  height: 2.5rem;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.isSelected {
    background: colors.$logoColor;
    color: #fff;
  }
}

.customFooterStyles {
  display: flex;
  justify-content: flex-end;
}

.map {
  position: relative;
}

.searchBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  margin: 1rem;
  height: 3.5rem;
}

.searchInput {
  flex: 1;
  position: relative;
  background: #fff;
  border-radius: 0.25rem;
}

.input {
  @include mixins.inputReset;
  @include typography.regular(16px, 20px, $fontFamily: typography.$fontFamilySystem);

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 1.5rem 1rem 0.5rem;
  border-radius: inherit;

  &:focus {
    @include mixins.inputFocusAppearance;
    outline: none;
    box-shadow: 0 0 0 2px colors.$inputFocusedColor;
  }
}

.inputLabel {
  @include typography.regular(12px, 16px, $fontFamily: typography.$fontFamilySystem);

  color: colors.$neutral600;
  position: absolute;
  top: 0.625rem;
  left: 1rem;
  z-index: 3;
}

.searchButton {
  @include mixins.buttonReset;

  background: #1b1c22;
  border-radius: 4px;
  color: #fff;
  width: 80px;
  margin-left: 1rem;
  cursor: pointer;
}

.distance {
  @include typography.regular(16px, 20px);

  flex: 0 1 4rem;
  color: colors.$neutral600;
  white-space: pre;
  margin-left: auto;
  text-align: right;
}

.arrowRightIcon {
  @include typography.regular(16px, 20px);

  color: colors.$neutral600;
  white-space: pre;
  margin-left: auto;
  margin-top: 1.5rem;
}

.detailsRightSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  padding-left: 0.5rem;
}

.scrollIconArea {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: colors.$neutral600;
  padding-bottom: 1rem;
}

.noMailInOption {
  padding-bottom: 2rem;
}

.maximizeIcon {
  height: 20px;
}

.moreOptionsText {
  color: colors.$textColorDark;
}

.printAndMailReturnButton {
  width: 100%;
  border-radius: 0px;
  color: colors.$linkColor;
}

.printAndMailReturnArea {
  background-color: colors.$buttonLightBackgroundColor;
  margin: 16px -8px;
}

.floatingLocationCard {
  display: flex;
  margin: 0.5rem;
  margin-right: 3.5rem;
  padding: 0.7rem;
  position: absolute;
  bottom: 20px;
  background-color: white;
  z-index: 9999;
}
