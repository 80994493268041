@use 'src/sass/mediaQueries';
@use 'src/sass/mixins';
@use 'src/sass/typography';

$zIndexModal: 20;
$modalMaxWidth: 928px;

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zIndexModal;
  height: 100%;

  &:focus {
    outline: none;
  }
}
.modal {
  position: relative;
  background: #fff;
  max-width: $modalMaxWidth;
  margin: 1rem auto 0;
  border-radius: 12px;
  display: flex;
  flex-flow: column nowrap;
  max-height: calc(100% - 24px);

  @include mediaQueries.forTabletPortraitUp {
    margin-top: 2rem;
  }

  @include mediaQueries.forDesktopUp {
    margin-top: 2rem;
    max-width: 75%;
  }

  @include mediaQueries.forBigDesktopUp {
    margin-top: 2rem;
    max-width: 60%;
  }
}

.modalContent {
  height: 100%;
  overflow-y: auto;
}

.title {
  @include typography.medium(24px, 32px);
  padding: 1.5rem 2.5rem 1.5rem 1rem;
}

.footer {
  padding: 1rem;
  display: flex;
  flex-flow: row nowrap;

  & > *:not(:first-child) {
    margin-left: 1rem;
  }
}

.closeButton {
  @include mixins.buttonReset;

  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  z-index: $zIndexModal + 1;
  color: #757784;
  cursor: pointer;

  &:hover {
    color: #0b0c0d;
  }

  &:active {
    transform: translateY(1px);
  }
}

.modalBackdrop {
  background: #0b0c0d;
  opacity: 0.4;
  position: fixed;
  top: 0;
  z-index: $zIndexModal - 1;
  left: 0;
  right: 0;
  bottom: 0;
}
