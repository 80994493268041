@use 'src/sass/colors';
@use 'src/sass/mixins';
@use 'src/sass/typography';

.container {
  padding-top: 4rem;
}

.textInput {
  margin: 0 0 1rem;

  &:nth-child(2) {
    margin-bottom: 3.5rem;
  }
}

.textInput {
  margin: 0 0 1rem;

  &:nth-child(2) {
    margin-bottom: 3.5rem;
  }
}

.startReturnButton {
  width: 100%;
  display: block;
  text-align: center;
}

.questionsLink {
  font: 400 16px/25px typography.$fontFamilyStore;
  margin: 2rem 0;
  text-align: center;
  color: #676565;
}

.emailPlaceholder {
  display: block;
  margin: 0.25rem auto 0;
}

.errorMessage {
  @include typography.regular(16px, 28px);

  color: colors.$errorColor;
  margin: 0 0 1.5rem;
}
